<template>
  <div class="k-9sptci" v-loading="loading">
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="门派" name="sect">
        <!-- 查询数据 -->
        <div slot class="clearfix">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="门派ID" prop="id">
              <el-input placeholder="门派ID" v-model="searchForm.id"></el-input>
            </el-form-item>
            <el-form-item label="门派名称" prop="name">
              <el-input
                placeholder="门派名称"
                v-model="searchForm.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="当前状态">
              <el-select
                @change="handleDepot()"
                clearable
                v-model="searchForm.status"
                placeholder="请选择查询条件"
              >
                <el-option label="正常" value="1"></el-option>
                <el-option label="禁赛" value="0"></el-option>
              </el-select>
            </el-form-item>

            <el-button
              @click="handleQueryTeam()"
              style="margin-left: 20px; padding: -9px 30px; font-size: 15px"
              type="primary"
              >查 询</el-button
            >
            <el-button
              @click="addUser"
              style="margin-left: 20px; margin-right: 20px;padding: -9px 30px; font-size: 15px"
              type="primary"
              >新增黑名单</el-button
            >
            <el-form-item>
              <importButton
                import-url="/admin/import/import_sect_black"
                status-url="/admin/import/import_sect_black_status"
                :data="{ match_id: match_id }"
                @refushList="handleQueryTeam()"
              ></importButton>
            </el-form-item>
            <el-form-item>
              <!-- import_template使用s1模板是因为模板通用，不需要区分 -->
              <importHistoryButton
                history_url="/admin/import/import_sect_black_history"
                :import_template="`import_team_black`"
              ></importHistoryButton>
            </el-form-item>
          </el-form>
        </div>

        <el-table :data="dataTable" style="margin-top:20px" border>
          <el-table-column
            v-for="item in dataTeamList"
            class="status"
            :key="item._id"
            :prop="item.prop"
            :type="item.type"
            :label="item.label"
            :width="item.width"
            :formatter="item.formatter"
          >
          </el-table-column>
          <el-table-column label="当前状态" width="200">
            <!-- 使用 scoped slot 自定义表格列的内容 -->
            <template slot-scope="{ row }">
              <span :style="{ color: row.status == 0 ? 'red' : '#606266' }">
                {{ row.status == 0 ? "禁赛" : "正常" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                @click="handleEdit(scope.row)"
                style="margin-left: 10px"
                >编辑黑名单</el-button
              >
              <el-button
                style="margin-left: 10px"
                @click.native.stop="handleDelete(scope.row._id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination
            @current-change="handleQueryTeam"
            :current-page.sync="pager.pageNum"
            layout="prev, pager, next"
            :total="pager.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="个人" name="person">
        <!-- 查询数据 -->
        <div slot class="clearfix">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="选手UID">
              <el-input
                placeholder="请输入选手UID"
                v-model="searchForm.uid"
                @keyup.enter.native="handleQuery()"
              />
            </el-form-item>
            <el-form-item label="当前状态">
              <el-select
                @change="handleDepot()"
                clearable
                v-model="searchForm.status"
                placeholder="请选择查询条件"
              >
                <el-option label="正常" value="1"></el-option>
                <el-option label="禁赛" value="0"></el-option>
              </el-select>
            </el-form-item>

            <el-button
              @click="handleQuery()"
              style="margin-left: 20px; padding: -9px 30px; font-size: 15px"
              type="primary"
              >查 询</el-button
            >
            <el-button
              @click="addUser"
              style="margin-left: 20px; margin-right: 20px;padding: -9px 30px; font-size: 15px"
              type="primary"
              >新增黑名单</el-button
            >
            <el-form-item>
              <importButton
                import-url="/admin/import/import_member_black"
                status-url="/admin/import/import_member_black_status"
                :data="{ match_id: match_id }"
                @refushList="handleQuery()"
              ></importButton>
            </el-form-item>
            <el-form-item>
              <!-- import_template使用s1模板是因为模板通用，不需要区分 -->
              <importHistoryButton
                history_url="/admin/import/import_member_black_history"
                :import_template="`import_user_black`"
              ></importHistoryButton>
            </el-form-item>
          </el-form>
        </div>

        <el-table :data="dataTable" style="margin-top:20px" border>
          <el-table-column
            v-for="item in dataList"
            class="status"
            :key="item._id"
            :prop="item.prop"
            :type="item.type"
            :label="item.label"
            :width="item.width"
            :formatter="item.formatter"
          >
          </el-table-column>
          <el-table-column label="当前状态" width="200">
            <!-- 使用 scoped slot 自定义表格列的内容 -->
            <template slot-scope="{ row }">
              <span :style="{ color: row.status == 0 ? 'red' : '#606266' }">
                {{ row.status == 0 ? "禁赛" : "正常" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                @click="handleEdit(scope.row)"
                style="margin-left: 10px"
                >编辑黑名单</el-button
              >
              <el-button
                style="margin-left: 10px"
                @click.native.stop="handleDelete(scope.row._id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination
            @current-change="handleQuery"
            :current-page.sync="pager.pageNum"
            layout="prev, pager, next"
            :total="pager.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :title="view_type == 'add' ? '新增黑名单' : '修改黑名单'"
      :visible.sync="userVisible"
      v-if="userVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="500px"
    >
      <el-form ref="userForm" :model="userData" label-width="100px">
        <el-form-item
          v-if="activeName != 'sect'"
          label="选手UID"
          prop="uid"
          :rules="[
            {
              required: true,
              message: '请输入选手UID',
              trigger: ['blur'],
            },
            {
              max: 8,
              message: '最多8个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.uid"
            placeholder="请输入选手UID"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="activeName == 'sect'"
          label="门派id"
          prop="uid"
          :rules="[
            {
              required: true,
              message: '请输入门派id',
              trigger: ['blur'],
            },
            {
              max: 10,
              message: '最多10个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.uid"
            placeholder="请输入门派id"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="activeName != 'sect'"
          label="选手昵称"
          prop="name"
          :rules="[
            {
              required: true,
              message: '请输入选手昵称',
              trigger: ['blur'],
            },
            {
              max: 20,
              message: '最多20个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.name"
            placeholder="请输入昵称"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="activeName == 'sect'"
          label="门派昵称"
          prop="name"
          :rules="[
            {
              required: true,
              message: '请输入门派昵称',
              trigger: ['blur'],
            },
            {
              max: 20,
              message: '最多20个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="userData.name"
            placeholder="请输入昵称"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="禁赛截止"
          prop="expiredTime"
          :rules="[
            {
              type: 'date',
              required: true,
              message: '请选择禁赛截止日期',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-date-picker
            v-model="userData.expiredTime"
            type="datetime"
            placeholder="请选择禁赛截止日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveBlackUser">确定</el-button>
          <el-button @click="userVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { MessageBox } from "element-ui";
import importButton from "../../match/components/importButton";
import importHistoryButton from "../../match/components/importHistoryButton";
export default {
  name: "s1_banners",
  computed: {
    ...mapState("match", ["match_types"]),
  },
  components: {
    importButton,
    importHistoryButton,
  },
  data() {
    return {
      pager: {
        total: 10,
        pageNum: 1,
      },
      activeName: "sect",
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      searchForm: {
        uid: "",
        status: "",
      },
      dataTable: [],
      dataTeamList: [
        {
          prop: "sect_id",
          label: "门派ID",
          width: 200,
        },
        {
          prop: "sect_name",
          label: "门派昵称",
          width: 200,
        },
        {
          prop: "expiredTime",
          label: "禁赛截止",
          width: 300,
          formatter: (row) => {
            return new Date(row.expiredTime).format("yyyy-MM-dd hh:mm:ss");
          },
        },
        {
          prop: "createTime",
          label: "创建时间",
          width: 300,
          formatter: (row) => {
            return new Date(row.createTime).format("yyyy-MM-dd hh:mm:ss");
          },
        },
      ],
      dataList: [
        {
          prop: "uid",
          label: "选手UID",
          width: 200,
        },
        {
          prop: "name",
          label: "选手昵称",
          width: 200,
        },
        {
          prop: "expiredTime",
          label: "禁赛截止",
          width: 300,
          formatter: (row) => {
            return new Date(row.expiredTime).format("yyyy-MM-dd hh:mm:ss");
          },
        },
        {
          prop: "createTime",
          label: "创建时间",
          width: 300,
          formatter: (row) => {
            return new Date(row.createTime).format("yyyy-MM-dd hh:mm:ss");
          },
        },
      ],
      userVisible: false,
      userData: {
        _id: "",
        expiredTime: "",
        name: "",
        uid: "",
      },
      view_type: "",
      match: false,
    };
  },
  methods: {
    //切换tab
    handleClick() {
      this.pager.pageNum = 1;
      if (this.activeName == "sect") {
        this.handleQueryTeam();
      } else {
        this.handleQuery();
      }
    },
    // 添加初始化
    addUser() {
      this.view_type = "add";
      this.userData._id = "";
      this.userData.expiredTime = "";
      this.userData.name = "";
      this.userData.uid = "";
      this.userVisible = true;
    },
    //编辑赋值
    handleEdit(row) {
      this.view_type = "edit";
      this.userData._id = row._id;
      this.userData.expiredTime = new Date(row.expiredTime);
      if (this.activeName == "sect") {
        this.userData.name = row.sect_name;
        this.userData.uid = row.sect_id;
      } else {
        this.userData.name = row.name;
        this.userData.uid = row.uid;
      }

      this.userVisible = true;
    },
    //添加banner
    saveBlackUser() {
      this.$refs.userForm.validate(async (valid) => {
        //校验
        if (valid) {
          this.loading = true;
          if (this.activeName == "sect") {
            let { data, code } = await this.$http.operate.save_black_sect({
              _id: this.userData._id,
              sect_id: this.userData.uid,
              sect_name: this.userData.name,
              expiredTime: this.userData.expiredTime,
            });
            this.loading = false;

            if (code == 0) {
              this.$message({
                type: "success",
                message: "保存成功",
              });

              this.userVisible = false;
              this.handleQueryTeam();
            } else {
              this.userVisible = true;
            }
          } else {
            let { data, code } = await this.$http.operate.save_black(
              this.userData
            );
            this.loading = false;

            if (code == 0) {
              this.$message({
                type: "success",
                message: "保存成功",
              });
              this.userVisible = false;
              this.handleQuery();
            } else {
              this.userVisible = true;
            }
          }
        }
      });
    },
    //删除
    async handleDelete(_id) {
      try {
        await MessageBox.confirm("是否确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        if (this.activeName == "sect") {
          const { code } = await this.$http.operate.delete_black_team({
            _id: _id,
          });
          if (code === 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.handleQueryTeam();
          }
        } else {
          const { code } = await this.$http.operate.delete_black({
            _id: _id,
          });
          if (code === 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.handleQuery();
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    //重置回收站的初始页码
    handleDepot() {
      this.pager.pageNum = 1;
      if (this.activeName == "sect") {
        this.handleQueryTeam();
      } else {
        this.handleQuery();
      }
    },
    //查询列表
    async handleQuery() {
      const { pageNum } = this.pager;
      // this.loading = true;
      //请求接口
      const { data, errorCode } = await this.$http.operate.list_black({
        ...this.searchForm,
        pageNum,
      });
      this.loading = false;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    async handleQueryTeam() {
      const { pageNum } = this.pager;
      // this.loading = true;
      //请求接口
      const { data, errorCode } = await this.$http.operate.list_black_team({
        sect_id: this.searchForm.id,
        sect_name: this.searchForm.name,
        status: this.searchForm.status,
        pageNum,
      });
      this.loading = false;
      this.dataTable = data.data;
      this.pager = data.pager;
    },

    //tab的变化
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async mounted() {
    //查询列表
    this.handleQueryTeam();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
.status {
  color: red;
}
</style>
